
/*
URLs para llamadas a la API
*/

let URL_Production = "https://api-fichajes.ioon.es/api/"
let URL_Development = "https://apid-ioontr.ioon.dev/api/"
let URL_Local =  "http://127.0.0.1:8000/api/"
let URL_Docker =  "http://localhost:5695/api/"
 
let URL = URL_Production
//let URL = URL_Docker;

/* URLs de USUARIO / ROLES / PERMISOS  --- ELIMINADAS*/ 

//trae el usuario logueado x token
export const selectUsuario = URL + "user"

export const login =  URL + "login"//actual
export const logout =  URL + "logout"//actual
// --------- Toda Informacion Dashboard  ---------  //
export const selectInfoDash = URL + "dashboard"//actual
export const employee = URL + "employee"//actual
export const getEmpleado = URL +"getEmpleado" 
export const updatePassword = URL +"updatePassword" 
export const updateConfig = URL +"updateConfig" 
export const saveDraft = URL +"saveDraft" 
export const registrarUsuario =  URL + "auth/signup"
export const reset = URL + "recoverPassword" //prueba
export const selectEmpleado =  URL + "selectEmpleado"
export const selectVacaciones =  URL + "selectVacaciones"
export const selectUsers =  URL + "selectUsers" //prueba
export const updateUsers =  URL + "updateUsers" //prueba

/* ---------- PRODUCCION ------------------ */
export const getFacturaForm = URL + "getFacturaForm"
export const makeFacturaProduccion = URL + "makeFacturaProduccion"
export const generarPDF = URL + "generarPDF"
// tipo`, `suc`, `year`, `ultimo`, `isActive
/* SIN HACER TODAVIA --> para modulo Mantenimiento>Empresa
*/
export const selectBolsaCompensaciones = URL + "selectBolsaCompensacion"
export const getProyectoHorario = URL + "getProyectoHorario" // trae desde tabla EmpleadoProyectoHorario a partir del id de empleado, especifica datos del proyecto y el horario --> JOIN TABLE
export const getProyectoHorarioParaFichaje = URL + "getProyectoHorarioParaFichaje?id=" // trae desde tabla EmpleadoProyectoHorario a partir del id de empleado, especifica datos del proyecto y el horario --> JOIN TABLE
//ENVIO empleado_id y proyecto_id, devuelve info del EPH y Calndario completo.
///////
export const insertFichaje = URL + "insertFichaje"
export const deleteFichaje = URL + "deleteFichaje"
export const selectFichajeWithData = URL + "selectFichajeWithData"
export const getLastFichaje = URL + "getLastFichaje"
// --------- Enviar Correo Vacaciones  ---------  //
export const enviarCorreoVacaciones = URL + "enviarCorreoVacaciones"
// --------- RRHH DASH : Quienes no ficharon ---------  //
export const noFichaje = URL + "noFichaje"
export const noFichajeRango = URL + "noFichajeRango"
export const dashboardRRHH = URL + "dashboardRRHH"
// --------- PARA TRAER SOLO LO QUE DEBE VER EL ROL USER  ---------  //
export const getFichajeWithData = URL + "getFichajeWithData"
export const getDocumentos = URL + "viewAllDocuments?empleado_id=" //con id de empleado
// --------- SERVIDORES  ---------  //
export const getDropletInfo = URL + "getDropletInfo" //get (no necesita el token)
export const insertServidorCorreo  = URL + ""
export const getServidorCorreo  = URL + ""

// --------- ADMINISTRACION  ---------  //
export const getRoles = URL + "role"
export const insertRoles = URL + "role"
export const employees = URL + "employee"
export const employeeUpdate = URL + "employeeUptade"
export const projects = URL + "project"
export const calendarioFestivo   = URL + "calendariofestivo"
export const client   = URL + "clients"
export const projectByClient   = URL + "project/client/"
export const tipoHora   = URL + "tipoHora"
export const tipoTarea   = URL + "tipoTarea"
export const getEmpleadoById = URL + "getEmpleadoById"
export const provincias = URL + "provincia"

export const reportFichajeDia = URL + "report/fichajeAlDia"
export const reportFichajeAlMes = URL + "report/fichajeAlMes"
export const reportImputacion = URL + "report/imputacion"
export const reportFichajeSemana = URL + "report/fichajeSemanal/"

export const importFileFinance = URL + "report/import-excel-finance"
export const importFileVacation = URL + "report/import-excel-vacation"
export const importFileAbsentismo = URL + "report/import-excel-absentismo"
export const exportFileExcel = URL + "report/export-excel";

// client
export const addClient = URL + 'client/add'
export const getClientById = URL + 'client/'
export const updateClient = URL + 'client/change';
export const getAllClients = URL + 'client';
//dar baja empleado
export const bajaEmpleadoUrl = URL + 'baja-empleado/'