import React from "react";
import {Link} from "react-router-dom";

import { connect } from "react-redux";
import { addSession, addFoto } from "appRedux/actions/index";
import {  hideMessage } from "appRedux/actions/Auth";

import { login } from "routes/api/urls"
import {Button, Form, Input, message, Alert} from "antd";
import logo from '../assets/logos/logo_ioon_2020.png';
import logoIso9000 from '../assets/logos/LAB_ES.png';
import logoIso27000 from '../assets/logos/RGB_EN.png';
const FormItem = Form.Item;

function mapDispatchToProps(dispatch) {
  return {
    addSession: (session) => dispatch(addSession(session)),
    addFoto: (foto) => dispatch(addFoto(foto))
  };
}

class SignInProvisorio extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email: "",
      password: "", 
      showError: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  async componentDidMount(){
    //debugger;
    // let appState = {
    //   isLoggedIn: false,
    //   user_email: "",
    //   user_id: "",
    //   rol_id: "",
    //   auth_token: {}
    // };
    
  }
  async componentDidUpdate(){
    debugger;
    if (this.state.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
        this.setState({showMessage : false});
      }, 100);
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    debugger;
    fetch(login, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({email: this.state.email, password: this.state.password}), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'X-Requested-With' : 'XMLHttpRequest',
        'Accept':'*/*'
      }
    })
    .then(res => res.json())
    .then(response => {
        console.log(response, "----------")
        debugger;
        if(response.message !== "Unauthorized."){
            let auth_token = response.data.access_token
            let session = {
              auth_token: response.data.access_token,
              isLoggedIn: true,
              user_id: response.data.user_id,
              user_email: response.data.user_email,
              user_name: response.data.user,
              empleado_id: response.data.empleado_id,
              empleado_nombre: response.data.nombre,
              empleado_primerApellido: response.data.primerApellido,
              empleado_segundoApellido: response.data.segundoApellido,
              rol: response.data.rol,
              duracionJornada: response.data.duracionJornada,
              inicioJornada: response.data.inicioJornada
            }
            this.props.addSession( session );

            //this.props.addFoto( foto );
            //console.log(this.props)
            //console.log("auth_token", auth_token)
            this.setState({ 
              email: "",
              password: "", 
            });
            if(auth_token){ 
              let appState = {
                isLoggedIn: true,
                auth_token: auth_token,
                user_email: this.state.email,
              };
              // save app state with user date in local storage
              
              
              this.props.loginUser(appState);
            }
        } else {
          this.setState({
            showMessage: true,
            alertMessage: "Credenciales Invalidas"
          })
        }
    }).catch(error => console.error('Error:', error));


    
  }

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value })
    this.setState({ showError: false })
  }
    
    render(){
        const {showMessage, alertMessage} = this.state; //this.props
        
        return(
            <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
          
              <div className="gx-app-login-main-content">
              {/* <div className="color-teal">Time Report</div> */}
                <div className="gx-app-logo-content">
                  
                  <div style={{ textAlign: "center" }} className="gx-app-logo">
                    <img style={{ width: "30%", marginTop: "10px" }} src={logo} alt='Logo'/><br/>
                    <img style={{ width: "30%", marginTop: "10px" }} src={logoIso9000} alt='Logo'/>
                    <img style={{ width: "30%", marginTop: "10px" }} src={logoIso27000} alt='Logo'/>
                  </div>
               
                  
                  
                </div>
                <div className="gx-app-login-content ">
                

                
        {/* <Form onSubmit={this.handleLogin} className="gx-signin-form gx-form-row0">*/}
        <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                    <FormItem>   
                        <Input 
                          value={this.state.email}
                          autoComplete="on"
                          id="email-input"
                          name="email"
                          type="text"
                          className="center-block"
                          placeholder="Email"
                          onChange={this.handleChange}
                        />
                    </FormItem>
                    <FormItem>
                        <Input 
                          value={this.state.password}
                          autoComplete="on"
                          id="password-input"
                          name="password"
                          type="password"
                          className="center-block"
                          placeholder="Password"
                          onChange={this.handleChange}
                        />
                    </FormItem>
                    { this.state.showError ? 
                    <Alert message="Datos de acceso incorrectos" type="error" /> :
                    null
                    }
                    <FormItem>
                      <Button type="primary" className="gx-mb-0" htmlType="submit">
                        Entrar
                        
                      </Button> <Link to="/recuperar-pass">Recuperar Contraseña</Link>
                    </FormItem>
                  </Form>
                  
                </div>
    
                
                {showMessage 
                    ? message.error(alertMessage.toString()) 
                    : null}
              </div>
            </div>
          </div>        )
    }
}
const LoginForm = connect(
  null,
  mapDispatchToProps
)(SignInProvisorio);
export default connect( null,{hideMessage}) (LoginForm);