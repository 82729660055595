import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import DayPicker from 'react-day-picker';
import classNames from 'classnames';

import {
  Col, Row, Alert,
  Button, Spin,
  Select, Input, Form,
  Card, Modal, Table, Icon, Checkbox
} from "antd";
import { Excel } from "antd-table-saveas-excel";
import {CSVLink} from "react-csv"
//Importacion para pop up
import {message} from 'antd'

import {
  reportFichajeDia,
  reportFichajeAlMes,
  reportImputacion,
  importFileFinance,
  importFileVacation,
  importFileAbsentismo,
  exportFileExcel
} from "routes/api/urls";
import { post, get, put, postWithDocument } from "routes/api/Llamadas";
import moment from "moment";

const { Option } = Select;

const mapStateToProps = state => ({
  session: state.settings.session
});

const WEEKDAYS_SHORT = {
  es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
};
const MONTHS = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
};

const dashColumns = [
  {
    title: "Identificación",
    dataIndex: "Identificacion",
    key: "Identificacion"
  },
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Provincia",
    dataIndex: "Provincia",
    key: "Provincia"
  },
  {
    title: "Cliente",
    dataIndex: "Cliente",
    key: "Cliente"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Email",
    dataIndex: "eMail",
    key: "eMail"
  },
  {
    title: "Fecha",
    dataIndex: "Fecha",
    key: "Fecha"
  },
  {
    title: "Hora Inicio",
    dataIndex: "Hora_inicio",
    key: "Hora_inicio"
  }
];

const dashColumnsMes = [
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Calendario",
    dataIndex: "Calendario",
    key: "Calendario"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
  {
    title: "Fecha",
    dataIndex: "F_Calendario",
    key: "F_Calendario"
  },
  {
    title: "Festivo",
    dataIndex: "Festivo",
    key: "Festivo"
  },
  {
    title: "Inicio de Fichaje",
    dataIndex: "Fich_INI",
    key: "Fich_INI"
  },
  {
    title: "Fin de Fichaje",
    dataIndex: "Fich_FIN",
    key: "Fich_FIN"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Identificación",
    dataIndex: "Identificacion",
    key:'IdentiMes'
  },
  {
    title: "Email",
    dataIndex: "eMail",
    key: "eMail"
  }

];

const dashColumnsImp = [
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Fecha",
    dataIndex: "Fecha",
    key: "Fecha"
  },
  {
    title: "Calendario",
    dataIndex: "Calendario",
    key: "Calendario"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
  {
    title: "Festivo",
    dataIndex: "Festivo",
    key: "Festivo"
  },
  {
    title: "Cliente",
    dataIndex: "Cliente",
    key: "Cliente"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Tiempo Imputado Proyecto",
    dataIndex: "T_Imputado_Proyecto",
    key: "T_Imputado_Proyecto"
  },
  {
    title: "Tipo de Horas",
    dataIndex: "Tipo_horas",
    key: "Tipo_horas"
  },
  {
    title: "Tiempo Total Fichaje",
    dataIndex: "T_Total_Fichaje",
    key: "T_Total_Fichaje"
  },
  {
    title: "Tarea",
    dataIndex: "tarea",
    key: "tarea"
  },
  {
    title: "Subtarea",
    dataIndex: "subtarea",
    key: "subtarea"
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones"
  },
  {
    title: "Identificacion",
    dataIndex: "Identificacion",
    key: "Identificacion"
  },
  {
    title: "Email Corporativo",
    dataIndex: "emailCorp",
    key: "emailCorp"
  },
  {
    title: "Telefono",
    dataIndex: "telefono",
    key: "telefono"
  },
  {
    title: "Fecha de Alta",
    dataIndex: "fechaAlta",
    key: "fechaAlta"
  },
  {
    title: "Fecha de Baja",
    dataIndex: "fechaBaja",
    key: "fechaBaja"
  }
];

class ReportsUpload extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      loading: true,
      dataSourceFichajeDia: [],
      dataSourceFichajeMes: [],
      dataSourceImputacion: [],
      visibleAlDia: true,
      visibleMes: false,
      visibleImputacion: false,
      pickDate : false,
      fechaCurrent: new Date(moment()),
      fechaCurrentShow: moment().format("YYYY-MM-DD"),
      fields : { month: ''},
      months : MONTHS,
      monthSelected: moment(new Date()).format("MM") -1,
      anhoSelected: moment(new Date()).format("Y"),
      selectedFileFinance : null,
      nameBtnUploadFinance : 'Subir Archivo Finanzas',
      selectedFileVacation : null,
      nameBtnUploadVacation : 'Subir Archivo Vacaciones',
      selectedFileAbsentismo : null,
      nameBtnUploadAbsentismo : 'Subir Archivo Absentismo'
    };

  }

  async componentDidMount() {
    //debugger;
    this.setState({loading:true});//mascara para mostrar cargando
    
    await this.getFichajeDia();
  }

  getFichajeDia = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true });
    let resp = await get(reportFichajeDia, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      //console.log(resp.data);
      debugger;
      this.setState({
        dataSourceFichajeDia : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  getFichajeMes = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true, dataSourceFichajeMes: []});
    let date = { date : this.state.fechaCurrentShow}
    let resp = await post(date, reportFichajeAlMes, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      console.log(resp.data);
      debugger;
      this.setState({
        dataSourceFichajeMes : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  getFichajeImputacion = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true , dataSourceImputacion: []});
    let date = { date : this.state.fechaCurrentShow}
    let resp = await post(date, reportImputacion, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      //console.log(resp.data);
      debugger;
      this.setState({
        dataSourceImputacion : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  verListadoAlDia = async () => {
    this.setState({
      visibleAlDia: true,
      visibleMes: false,
      visibleImputacion: false
    });
  }

  verListadoAlMes = async () => {
    this.setState({
      visibleAlDia: false,
      visibleMes: true,
      visibleImputacion: false
    });
    await this.getFichajeMes();
  }

  verListadoImputacion = async () => {
    this.setState({
      visibleAlDia: false,
      visibleMes: false,
      visibleImputacion: true
    });
    await this.getFichajeImputacion();
  }

  handleClickDia = () => {
    const {auth_token} = this.props.session;
    const finalColumns=[
      {
        "title": "Año",
        "dataIndex": "anio",
        "key": "anio"
      },
      {
        "title": "Mes",
        "dataIndex": "mes",
        "key": "mes"
      },
      {
        "title": "Empleado",
        "dataIndex": "empleado",
        "key": "empleado"
      },
      {
        "title": "Cod_empleado",
        "dataIndex": "cod_empleado",
        "key": "cod_empleado"
      },
      {
        "title": "Cliente",
        "dataIndex": "cliente",
        "key": "cliente"
      },
      {
        "title": "Coste",
        "dataIndex": "coste",
        "key": "coste"
      },
      {
        "title": "Normal_fee",
        "dataIndex": "normal_fee",
        "key": "normal_fee"
      },
      {
        "title": "Other_fee",
        "dataIndex": "other_fee",
        "key": "other_fee"
      },
      {
        "title": "Horas_laborales",
        "dataIndex": "horas_laborales",
        "key": "horas_laborales"
      },
      {
        "title": "Vacaciones",
        "dataIndex": "vacaciones",
        "key": "vacaciones"
      },
      {
        "title": "Ausencias",
        "dataIndex": "ausencias",
        "key": "ausencias"
      },
      {
        "title": "Horas producción",
        "dataIndex": "horas_produccion",
        "key": "horas_produccion"
      }
      
    ]

    get(exportFileExcel, auth_token)
      .then(resp=>{
        console.log('data--->',resp.data);
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(finalColumns)
          .addDataSource(resp.data, {
            str2Percent: true
          })
          .saveAs("FichajeAlDia.xlsx");
      })
      .catch(err=>console.log('Error de llamada'));
    
  };

  handleClickMes = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(dashColumnsMes)
      .addDataSource(this.state.dataSourceFichajeMes, {
        str2Percent: true
      })
      .saveAs("FichajeMes.xlsx");
  };

  handleClickImputacion = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(dashColumnsImp)
      .addDataSource(this.state.dataSourceImputacion, {
        str2Percent: true
      })
      .saveAs("Imputaciones.xlsx");
  };

  pickADate = async () => {
    //Levanta modal seleccion de fecha
    this.setState({pickDate : true});
  }
  closeDatePicker = async () => {
    //Cierra modal seleccion de fecha
    this.setState({pickDate : false});
  }
  manipulateDates = async (date) => {
    //Aquí haremos toda la lógica para consultar la fecha  que se seleccione
    this.setState({
      fechaCurrentShow: moment(date).format("Y-MM-DD"), 
      pickDate : false,
      anhoSelected: moment(date).format("Y")
    });
    
  }

  handleDayClick  = async  (day, { selected, disabled }) => { //, { selected }

    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    let monthActual = moment().month(day).format('MM');
    let anoActual = this.state.anhoSelected;
    let fechaCompleta = anoActual+'-'+monthActual+"-01";
    let {visibleImputacion, visibleMes} = this.state;

    this.setState({
      fechaCurrent: day, //selected ? undefined :
      monthSelected: day,
      anhoSelected: anoActual,
      fechaCurrentShow: moment(fechaCompleta).format('YYYY-MM-DD')
    });
    //await this.manipulateDates(moment(fechaCompleta).format('YYYY-MM-DD'));
    //visibleMes ? await this.handleOk() : await this.handleOkImputacion(); ;
    
    console.log(day, this.state.fechaCurrent);
  }
  handleYearClick  = async  (year) => { //, { selected }

    let MesSelected = this.state.monthSelected;
    let monthActual = moment().month(MesSelected).format('MM');
    let anoActual = year;
    let fechaCompleta = anoActual+'-'+monthActual+"-01";

    this.setState({
      monthSelected: MesSelected,
      anhoSelected: anoActual,
      fechaCurrentShow: moment(fechaCompleta).format('YYYY-MM-DD')
    });
    //await this.manipulateDates(moment(fechaCompleta).format('YYYY-MM-DD'));
    //await this.handleOk();
    console.log(year, this.state.fechaCurrent);
  }

  handleChangeInput = (event) => {
   
    console.log(this.state.fechaCurrent);
    console.log(this.state.fechaCurrentShow);

    let name = event.target.name;

    console.log(name);
    console.log(event.target.value);
  }

  handleOk = async () => {

    this.setState({ loading: true });
    await this.getFichajeMes()

  };

  handleOkImputacion = async () => {

    this.setState({ loading: true });
    this.getFichajeImputacion()

  };

  handleFileChangeFinance = async(event) => {
    
    const file = event.target.files[0];
    this.setState({
      selectedFileFinance : file,
      loading: true,
      nameBtnUploadFinance : file.name
    });

      const {empleado_id, auth_token} = this.props.session;

      let resp = await postWithDocument(file, importFileFinance, auth_token);

      //debugger;
      
      if(resp.success == true){
        console.log(resp);
        //debugger;
        message.success('Carga exitosa')
        this.setState({ 
          loading: false, 
          nameBtnUploadFinance: 'Subir Archivo Finanzas'
         });
      }else{
        this.setState({ 
          loading: false,
          nameBtnUploadFinance: 'Subir Archivo Finanzas' 
        });
        let messageError = resp.message.split('(')[0];
        console.log(messageError)
        message.error('Revisa que los campos estén bien: ' +messageError)
      }
      

  };

  handleFileChangeAbsentismo = async(event) => {
    
    const file = event.target.files[0];
    this.setState({
      selectedFileAbsentismo : file,
      loading: true,
      nameBtnUploadAbsentismo : file.name
    });

    console.log(this.state.nameBtnUploadAbsentismo)

      const {empleado_id, auth_token} = this.props.session;
      // console.log("Imprimir fichero", file)
      console.log(auth_token, file)
      let resp = await postWithDocument(file, importFileAbsentismo, auth_token);

      console.log("RESP", resp)

      //debugger;
      if(resp.success == true){
        console.log(resp);
        //debugger;
        message.success('Carga exitosa')
        this.setState({ 
          loading: false, 
          nameBtnUploadAbsentismo: 'Subir Archivo Absentismo'
         });
      }else{
        this.setState({ 
          loading: false,
          nameBtnUploadAbsentismo: 'Subir Archivo Absentismo' 
        });
        let messageError = resp.message.split('(')[0];
        console.log(messageError)
        message.error('Revisa que los campos estén bien: ' +messageError)
      }
  };

  handleFileChangeVacation = async(event) => {
    
    const file = event.target.files[0];
    this.setState({
      selectedFileVacation : file,
      loading: true,
      nameBtnUploadVacation : file.name
    });

      const {empleado_id, auth_token} = this.props.session;

      let resp = await postWithDocument(file, importFileVacation, auth_token);

      //debugger;
      
      if(resp.success == true){
        console.log(resp);
        //debugger;
        message.success('Carga exitosa')
        this.setState({ 
          loading: false, 
          nameBtnUploadVacation: 'Subir Archivo Vacaciones'
         });
      }else{
        this.setState({ 
          loading: false,
          nameBtnUploadVacation: 'Subir Archivo Vacaciones' 
        });
        let messageError = resp.message.split('(')[0];
        console.log(messageError)
        message.error('Revisa que los campos estén bien: ' +messageError)
      }
      

  };

  render() { 
    let now = new Date();
    let MesSelected = moment(this.state.fechaCurrentShow).format('MM') -1 ;
    let b_month = moment(now).format("MM") -1
    let year = now.getFullYear();
    let b_day = moment(now).format("DD")
    const {loading} = this.state;

    const disabledDays =  [
      { daysOfWeek: [0,6]},
    ]

    let months = this.state.months.es;
 
    let mesesDrop = months.map(function(val, index){
      return {key:index, value:val};
    });
    let opciones = mesesDrop.map( x => <Option key={x.key} value={x.key} required={true}>{x.value} </Option>)
    let anhos = [year, year -1]
    let anhosDrop = anhos.map(function(val, index){
      return {key:index, value:val};
    });

    let opcionesAnho = anhosDrop.map( x => <Option key={x.value} value={x.value} required={true}>{x.value} </Option>)

    return (
      <div>
        <Spin spinning={loading}>
        <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
              <Card title="TARIFAS / FINANZAS" className="gx-card" extra={
            <span>  
              <div>{[
                  <Button key="btn_upload_finance" type="default" className={classNames({active: this.state.visibleAlDia})} >
                    <label htmlFor="file-upload-finance" className="custom-file-upload">
                      <input
                        type="file"
                        id="file-upload-finance"
                        onChange={this.handleFileChangeFinance}
                        style={{ display: 'none' }}
                      />
                      {this.state.nameBtnUploadFinance}
                    </label>
                  </Button>,
                <Button key="btn_upload_vacation" type="default"  onClick={() => this.verListadoAlDia()} className={classNames({active: this.state.visibleAlDia})}>
                    <label htmlFor="file-upload-vaca" className="custom-file-upload">
                      <input
                        type="file"
                        id="file-upload-vaca"
                        onChange={this.handleFileChangeVacation}
                        style={{ display: 'none' }}
                      />
                      {this.state.nameBtnUploadVacation}
                    </label>
                </Button>,
                <Button key="btn_upload_absen" type="default"  onClick={() => this.verListadoAlDia()} className={classNames({active: this.state.visibleAlDia})}>
                    <label htmlFor="file-upload-absen" className="custom-file-upload">
                      <input
                        type="file"
                        id="file-upload-absen"
                        onChange={this.handleFileChangeAbsentismo}
                        style={{ display: 'none' }}
                      />
                      {this.state.nameBtnUploadAbsentismo}
                    </label>
                </Button>
              ]}</div>
              </span>}>
                <div>
                    <div>
                      <div style={{marginBottom: "15px"}}>
                        <span>
                          Descarga el reporte
                        </span>
                      </div>
                      <span style={{marginBottom: "15px"}}>
                        <Button block type="primary" onClick={this.handleClickDia} style={{marginBottom: "0px", marginRight: "0px", width: "160px", marginBottom:"20px"}}>
                          Exportar Excel
                        </Button>
                      </span>
                      
                    </div>
                </div>
              </Card>
            </Col>
            </Row>
        </Spin>
        <Modal
          title="Seleccione Fecha"
          visible={this.state.pickDate}
          onCancel={this.closeDatePicker}
          width={350}
          footer={[
            <Button key="Finalizar" type="primary"
                  onClick={this.manipulateDates}
                   >
              Seleccionar
            </Button>
          ]}
        >
        <div>
          <DayPicker
            mode="single"
            selectedDays={this.state.fechaCurrent}
            useWeekdaysShort={true}
            weekdaysShort={WEEKDAYS_SHORT['es']}
            months={MONTHS['es']}
            onDayClick={this.handleDayClick}
            disabledDays={disabledDays}
          />
        </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(ReportsUpload);